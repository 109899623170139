<template>
    <div class="Information">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <p class="su_tit">계약안내</p>
                </div>
            </div>
        </div>
        <div class="sub_content">

            <div class="main">
                <input type="radio" id="tab-1" name="show" checked/>
                <input type="radio" id="tab-2" name="show" />
                <input type="radio" id="tab-3" name="show" />
                <div class="tab">
                    <label for="tab-1">일반공급</label>
                    <label for="tab-2">특별공급</label>
                    <label for="tab-3">당첨자조회</label>
                </div>
                <div class="content">
                    <!-- 일반공급 -->
                    <div class="content-dis">
                        <div class="titlebox" name="normal">
                            <div class="title">
                                <h2>일반공급</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                            <div class="textbox">
                                <p class="text">자격요건</p>
                                <p class="text1">1) 청년(대학생포함) 계층 신청자격</p>
                                <div class="line"></div>
                                <p class="text2">
                                    ① 만 19세 이상 만 39세 이하인 자 <br>
                                    ② 미혼 <br>
                                    ③ 무주택자 <br>
                                    ④ 자동차 무소유, 미운행자 <br>
                                    <span>(다만, 차량가액 3,496만원 이하의 장애인 사용(본인사용), 생업용, 125cc이하의 생업용 이륜차는 가능)</span>
                                </p>
                                <p class="text1">2) 신혼부부(혼인 후 2,555일) 및 예비신혼부부 계층 신청자격</p>
                                <div class="line"></div>
                                <p class="text2">
                                    ① 만 19세 이상 만 39세 이하인 자 <br>
                                    ② 신혼부부는 혼인중인자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는자 <br>
                                    ③ 무주택세대구성원(예비신혼부부의 경우 무주택자) <br>
                                    ④ 자동차 무소유, 미운행자 <br>
                                    <span>(다만, 차량가액 3,496만원 이하의 장애인 사용(본인사용), 생업용, 유자녀용, 125cc이하의 생업용 이륜차는 가능)</span>
                                </p>
                                <div class="line"></div>
                                <p class="text5">
                                    *소득 및 자산 기준 없음. <br>
                                    *준공 전 최초 임대주택 신청자는 홈페이지에 게시된 입주자모집공고문을 반드시 숙지 후 자격조건 및 제출서류 등을 확인 후 계약신청하시길 바랍니다.
                                </p>
                            </div>
                            <div class="application">
                                <p class="text">일반공급 신청서 작성</p>
                                <div class="contactSection">
                                    <div class="contact">
                                        <table>
                                            <tbody>
                                                <tr style="border-top: 2px solid #707070;">
                                                    <td class="content_title">성명</td>
                                                    <td class="box">
                                                        <input type="text" size="25"  placeholder="" name="user_name" id="user_Name" v-model="userData.user_name" required />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">전화번호</td>
                                                    <td class="box">
                                                        <input type="tel" max="11" size=30 v-model="userData.user_number" placeholder="" id="user_Number" /> '-'을 제외한 휴대폰번호를 입력해 주세요
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">생년월일</td>
                                                    <td class="box">
                                                        <input type="text" size="25" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData.user_birth" /> 예) 2019-12-31
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">거주유형</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData.residence_type" value="청년"> 청년</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData.residence_type" value="신혼부부"> 신혼부부</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">세대타입</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="17A"> 17A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="17B"> 17B</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="33A"> 33A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData.house_type" value="33B"> 33B</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">입주일</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-26"> 5월 26일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-27"> 5월 27일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-28"> 5월 28일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-29"> 5월 29일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-30"> 5월 30일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData.move_date" value="2022-05-31"> 5월 31일</label><br>
                                                        <span>(2022. 5.26 / 27 / 28 / 29 / 30 / 31 중 택1)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">선호층수</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="저층"> 저층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="중층" > 중층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData.prefer_floor" value="고층"> 고층부</label><br>
                                                        <span>(저층부 : 3~6, 중층부 : 7~12, 고층부: 13~19 중 택1)</span>
                                                    </td>	
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="txt">* 선호층수에 최대한 배정을 해드리고자하나 본인이 선호하는 층수에 반드시 배정되지 않을 수 있고, 당첨확률과는 전혀 무관함을 미리 알려드립니다.</p>
                                        <img src="@/assets/icon/신청하기.svg" @click="send(userData)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 특별공급 -->
                    <div class="content-dis">
                        <div class="titlebox">
                            <div class="title">
                                <h2>특별공급</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                            <div class="text">
                                <div class="textbox">
                                    <p class="text">자격요건</p>
                                    <p class="text1">1) 청년(대학생포함) 계층 신청자격</p>
                                    <div class="line"></div>
                                    <p class="text6">
                                        ① 만 19세 이상 만 39세 이하인 자 <br>
                                        ② 미혼 <br>
                                        ③ 무주택자 <br>
                                        ④ 자동차 무소유, 미운행자 <br>
                                        <span> (다만, 차량가액은 자동차 가액 3,496만 원 이하의 장애인 사용(본인사용), 생업용, 125cc이하의 생업용 이륜차는 가능)</span> <br>
                                        ⑤ 세대원별 월 평균소득 기준에 의함(모집공고문 참조 필수) <br>
                                        <span> - 신청인이 소득이 있는 경우는 “해당세대” 월평균 소득 </span> <br>
                                        <span> - 신청자가 소득이 있고 단독세대주 또는 세대원인 경우 신청자 본인만의 소득, 신청자가 세대원이 있는 세대주인 경우 무주택세대구성원 모두의 소득 </span> <br>
                                        <span> - 신청인이 소득이 없는 경우는 “부모”의 월평균 소득 </span> <br>
                                        ⑥ 본인 자산 가액 2억5,400만원 이하
                                    </p>
                                    <div class="line"></div>
                                    <p class="text5">
                                        *특별공급과 일반공급의 세대 내 마감을 비롯한 옵션 등 시공품질 및 제공옵션은 모두 동일합니다. <br>
                                        *준공 전 최초 임대주택 신청자는 홈페이지에 게시된 입주자모집공고문을 반드시 숙지 후 자격조건 및 제출서류 등을 확인 후 계약신청하시길 바랍니다.
                                    </p>
                                </div>
                            </div> 

                            <div class="application">
                                <p class="text">특별공급 신청서 작성</p>
                                <div class="contactSection">
                                    <div class="contact">
                                          <table>
                                            <tbody>
                                                <tr style="border-top: 2px solid #707070;">
                                                    <td class="content_title">성명</td>
                                                    <td class="box">
                                                        <input type="text" size="25" placeholder="" name="user_name" id="user_Name" v-model="userData2.user_name" required />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">전화번호</td>
                                                    <td class="box">
                                                        <input type="tel" max="11" size=30 v-model="userData2.user_number" placeholder="" id="user_Number" /> '-'을 제외한 휴대폰번호를 입력해 주세요
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">생년월일</td>
                                                    <td class="box">
                                                        <input type="text" size="25" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData2.user_birth" /> 예) 2019-12-31
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title"> 주소 <p style="margin-bottom:0; font: normal normal normal 12px/25px Noto Sans KR; letter-spacing: 0.24px; color: #3962D0;">주민등록상주소</p> </td>
                                                    <td class="box">
                                                        <input type="text"  size="50" placeholder="" name="address" id="Address"  v-model="userData2.address" > 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">월평균소득</td>
                                                    <td class="box">
                                                        <input type="int" size=25 placeholder="" name="income" id="Income"  v-model="userData2.income" > 원
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">자산</td>
                                                    <td class="box">
                                                        <input type="int" size=25 placeholder="" name="property" id="Property"  v-model="userData2.property" > 원
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">거주유형</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="residence_type" id="residence_type" v-model="userData2.residence_type" value="청년"> 청년</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">세대타입</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="17A"> 17A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="17B"> 17B</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="33A"> 33A</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="house_type" id="house_type" v-model="userData2.house_type" value="33B"> 33B</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">입주일</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-26"> 5월 26일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-27"> 5월 27일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-28"> 5월 28일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-29"> 5월 29일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-30"> 5월 30일</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="move_date" id="move_Date" v-model="userData2.move_date" value="2022-05-31"> 5월 31일</label><br>
                                                        <span>(2022. 5.26 / 27 / 28 / 29 / 30 / 31 중 택1)</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="content_title">선호층수</td>
                                                    <td class="box">
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="저층"> 저층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="중층" > 중층부</label>
                                                        <label><input type="radio" class="radio" placeholder="" name="prefer_floor" id="prefer_floor" v-model="userData2.prefer_floor" value="고층"> 고층부</label><br>
                                                        <span>(저층부 : 3~6, 중층부 : 7~12, 고층부: 13~19 중 택1)</span>
                                                    </td>	
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p class="txt">* 선호층수에 최대한 배정을 해드리고자하나 본인이 선호하는 층수에 반드시 배정되지 않을 수 있고, 당첨확률과는 전혀 무관함을 미리 알려드립니다.</p>
                                        <img src="@/assets/icon/신청하기.svg" @click="send2(userData2)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 당첨자조회 -->
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="title">
                                <h2>당첨자조회</h2>
                                <p class="line"><img src="@/assets/패스 22.png"></p>
                            </div>
                            <div class="winner">
                                <table>
                                    <tbody>
                                        <tr style="border-top: 2px solid #707070;">
                                            <td class="content_title">성명</td>
                                            <td>
                                                <input type="text" placeholder="" name="user_name" id="user_Name" v-model="userData3.user_name" required />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="content_title">전화번호</td>
                                            <td>
                                                <input type="tel" size=30 max="11" v-model="userData3.user_number" placeholder="" id="user_Number" /> '-'을 제외한 휴대폰번호를 입력해 주세요
                                            </td>
                                        </tr>
                                        <tr style="border-bottom: 2px solid #707070;">
                                            <td class="content_title">생년월일</td>
                                            <td> 
                                                <input type="text" class="non-kr" id="user_Birth" placeholder=""  aria-invalid="false" v-model="userData3.user_birth"/> 예) 2019-12-31
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <img src="@/assets/icon/신청하기.svg" @click="WinnerBtn(userData3)" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from 'axios';
export default {
    props: {
        type: {
            type: String,
            default: "",
        },
    },
    
    mounted() {
        console.log(this.$route.params.type)
        
        if(this.type == '특별공급') { 
            document.getElementById('tab-2').checked = true;
            // console.log(this.type)
            const id = document.getElementById('tab-2');
            console.log(id.checked)
        }
    },
	data() {
		return {
            // 일반공급
			userData:{
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
				residence_type: '', // 거주유형
				house_type: '', // 세대유형
				prefer_floor: '', // 선호층수
				move_date: '', // 입주일
			},
            // 특별공급
			userData2:{
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
				residence_type: '', // 거주유형
				house_type: '', // 세대유형
				prefer_floor: '', // 선호층수
				move_date: '', // 입주일
				income: '', // 월소득
				property: '', // 재산
				address: '', // 주소
			},
            // 당첨자조회
            userData3: { 
				user_name: '', // 이름
				user_birth: '', // 생년월일
				user_number: '', // 핸드폰 번호
            },
		}
	},
	methods: {
        // 일반공급
	send(list) {
			// 유효성검사 
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 
            
			console.log(list)			

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')
				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')
				return false;
			} 

			if(list.user_number.length == 0) {
				alert('핸드폰 번호 입력해주세요')
				return false;

			} else if(!phone.test(list.user_number)) {
				alert('핸드폰 번호를 확인해 주세요')
				return false;
			}

			if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')
				return false;

			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
                return false;
			}
			// 일반유형
			if(list.residence_type.length == 0) {
				alert('거주유형 선택해주세요')
				return false;
			} 
			if(list.house_type.length == 0) {
				alert('세대타입 선택해주세요')
				return false;
			} 

			if(list.move_date.length == 0) {
				alert('입주일 선택해주세요') 
				return false;
			}
			
			if(list.prefer_floor.length == 0) {
				alert('선호층 선택해주세요') 
				return false;
			}
            console.log("저장")

			axios.post('https://duckyoung2016.com:8080/apply', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
			.then(response => {
				console.log(response)

				if(response.data.statusCode == 200) {
					alert("신청 성공")
				}else {	
					alert("신청 실패");
					console.log(response)
				}
			}).catch(err => {
				console.log(err);
			})
        },
        // 특별공급
        send2(list){
			// 유효성검사 
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 
			var income = /^[0-9]*$/ // 월소득
			var property = /^[0-9]*$/ // 재산

            
			console.log(list)			

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')
				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')
				return false;
			} 

			if(list.user_number.length == 0) {
				alert('핸드폰 번호 입력해주세요')
				return false;

			} else if(!phone.test(list.user_number)) {
				alert('핸드폰 번호를 확인해 주세요')
				return false;
			}

			if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')
				return false;

			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
                return false;
			}
			if(list.address == 0) {
				alert('주소 입력해주세요')
				return false;
			}

			if(list.income == 0) {
				alert('월 소득 입력해주세요')
				return false;
			}else if(!income.test(list.income)){
				alert('월 소득 확인해주세요')
			}

			if(list.property == 0) {
				alert('재산 입력해주세요')
				return false;
			}else if(!property.test(list.property)){
				alert('재산 확인해주세요')
			}

			if(list.residence_type.length == 0) {
				alert('거주유형 선택해주세요')
				return false;
			} 

			if(list.house_type.length == 0) {
				alert('세대타입 선택해주세요')
                return false;
			} else if(list.house_type == '17A') {
				list.house_type = '17AS'
			} else if(list.house_type == '33A') {
				list.house_type = '33AS'
			} else if(list.house_type == '33B') {
				list.house_type = '33BS'
                // return false;
			}

			if(list.move_date.length == 0) {
				alert('입주일 선택해주세요') 
				return false;
			}
			
			if(list.prefer_floor.length == 0) {
				alert('선호층 선택해주세요') 
				return false;
			}
            console.log("저장")

			axios.post('https://duckyoung2016.com:8080/apply', JSON.stringify(list), {headers: {"Content-Type": `application/json`}})
			.then(response => {
				console.log(response)

				if(response.data.statusCode == 200) {
					alert("신청 성공")
				}else {	
					alert("신청 실패");
					console.log(response)
				}
			}).catch(err => {
				console.log(err);
			})
        },
        WinnerBtn(list){
          // 유효성검사
			var name = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/; //이름
			var birth = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ // 생년월일
			var phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; //핸드폰 번호 


            console.log(list)

			if(list.user_name.length == 0) {
				alert('이름을 입력해주세요')

				return false;

			} else if(!name.test(list.user_name)) {
				alert('이름을 올바로 입력해주세요')

				return false;
			} 
			if(list.user_birth.length == 0) {
				alert('생년월일 입력해주세요')

				return false;

			} else if(!birth.test(list.user_birth)){
				alert('생년월일 확인해주세요')
			}
			if(list.user_number.length == 0){
				alert('핸드폰 번호 입력해주세요')

				return false;

			} else if(!phone.test(list.user_number)){
				alert('핸드폰 번호를 확인해 주세요')

				return false;
			}
            console.log("조회") 

			axios.get('https://duckyoung2016.com:8080/winner',{
                params: {
                    user_name : "test111",
                    user_number : "01012341234",
                    user_birth :"2020-11-09"
                }
                
            }).then(response => {
                console.log(response)

				if(response.data.statusCode == 200) {
                    console.log(response)
                    alert("당첨되었습니다")
				}else if (response.data.statusCode == 103){	
					console.log("당첨자가 없습니다")  	
                    alert("당첨자없습니다")			
                }else {
                    alert("실패")
                }
			}).catch(err => {
				console.log(err);
			})
        }
	}
}
</script>


<style lang="scss">
.Information {position: relative; width: 100%;
    .inner{overflow: hidden;
        .sub_visual.sub1bg{background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../assets/img/menu_background.png") 0% 0% no-repeat padding-box; opacity: 0.9; width: 100%; height: 420px; background-size: cover;
            .in{width:100%; text-align: center; padding-top: 250px;
                .su_tit{text-align: center; font: normal normal bold 24px/29px GyeonggiTitleVOTF; letter-spacing: 0px; color: #FFFFFF; opacity: 1;}
            } 
        }
    }
    .sub_content{
        .main_title{position: relative; text-align: center;
            h2{margin-bottom: 60px; color: black; font-size: 38px; letter-spacing: -1px; font-weight: 500;} 
            h2:after{content: ''; display: block; margin: 15px auto 0; width: 35px; height: 3px; background: black;}
        }
        .main input[type=radio] { display: none; }
        #tab-1:checked ~ .tab label:nth-child(1),
        #tab-2:checked ~ .tab label:nth-child(2),
        #tab-3:checked ~ .tab label:nth-child(3) {box-shadow: inset 0 -3px 0 #002a9b;}
        .content > div { display: none;}

        #tab-1:checked ~ .content div:nth-child(1),
        #tab-2:checked ~ .content div:nth-child(2),
        #tab-3:checked ~ .content div:nth-child(3) { display: block;}
        .main { margin: 0 auto; max-width: 1280px;}
        .tab { overflow: hidden; border-bottom: 1px solid #E0E0E0; opacity: 1; text-align: center;}
        .tab label {width: 260px; font: normal normal normal 20px/29px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; cursor: pointer;text-align: center;text-align: center; padding: 15px 0; text-transform: uppercase; font-weight: bold; user-select: none; -webkit-user-select: none;}
        .tab label:visited{color: #002A9B;}
        .content {min-height: 1000px;}
        .content > div{ line-height: 1.5; font-size: 17px;}
        

        .titlebox{
            .title{margin-top: 3%; margin-bottom: 3%;
                h2{text-align: left; font: normal normal 900 32px/47px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1;}
                .line{margin-top: -15px; }
            }
            .textbox{
                .text{text-align: left; font: normal normal 500 28px/41px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1;}
                .text1{text-align: left; font: normal normal 500 16px/24px Noto Sans KR; letter-spacing: 0px; color: #333333;  opacity: 1}
                .text2{text-align: left;  letter-spacing: 0px; color: #727272; opacity: 1; font: normal normal 500 14px/20px Noto Sans KR; line-height: 3; margin-bottom: 5%;
                    span{padding-left:1%; text-align: left; font: normal normal 300 14px/20px Noto Sans KR; letter-spacing: 0px; color: #727272;} 
                }
                .text5{margin-top: 2%; margin-bottom: 10%; text-align: left; font: normal normal normal 12px/25px Noto Sans KR; letter-spacing: -0.24px; color: #626262; opacity: 1;}
                .text6{text-align: left;  letter-spacing: 0px; color: #727272; opacity: 1; line-height: 2; font: normal normal 500 14px/20px Noto Sans KR; line-height: 3;
                    span{padding-left:1%; text-align: left; font: normal normal 300 14px/20px Noto Sans KR; letter-spacing: 0px; color: #727272;} 
                }   
                .line{border: 1px solid #D5D5D5; opacity: 1; margin-bottom: 10px;}
            }
            // 일반공급 특별공급
            .application{ 
                // input[type=radio] { display: block; margin-top:5px; margin-right: 5px; float: left;}
                .text{text-align: left; font: normal normal 500 28px/41px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1;}
                table{width: 100%; margin-bottom: 10px; border-collapse: collapse; border-spacing: 0; 
                    .content_title{text-align: center; background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 208px; height: 50px; font: normal normal 500 16px/24px Noto Sans KR; letter-spacing: 1.92px; color: #333333;}
                    tr{border-bottom: 1px solid #E0E0E0; }
                    td{font-size: 15px; padding: 10px; overflow: hidden; word-break:normal; font-weight: 400;}
                }
                .txt{text-align: left; font: normal normal normal 12px/25px Noto Sans KR; letter-spacing: -0.24px; color: #626262; opacity: 1;}
                img{opacity: 1; display: block; margin: 0px auto; margin-top: 5%; cursor: pointer;}
                .radio{display: inline-block;}
                label{width: 85px; text-align: left; font: normal normal normal 14px/20px Noto Sans KR; letter-spacing: 1.5px; color: #626262; opacity: 1; margin-right: 50px; cursor: pointer;}
                .box{padding-left: 40px;
                    span{font: normal normal normal 12px/25px Noto Sans KR; letter-spacing: -0.24px; color: #727272; margin-top: 10px; vertical-align: middle;}
                }
                
            }
            // 당첨자조회
            .winner{
                table{width: 100%; margin-bottom: 10px; border-collapse: collapse; border-spacing: 0; 
                    .content_title{text-align: center; background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 208px;}
                    tr{border: 1px solid #E0E0E0; }
                    td{font-size: 15px; padding: 10px; overflow: hidden; word-break:normal; font-weight: 400;}
                }
                img{opacity: 1; display: block; margin: 0px auto; margin-top: 5%; cursor: pointer;}
            }
        }
        .contactSection{margin-bottom: 15%;}
    }
}
</style>